<i18n locale="th" lang="yaml">
onfield_cancel.page.title : "ยกเลิกงาน {serviceId} เรียบร้อย"
onfield_cancel.page.button : "เปิดใบซ่อมใหม่"
</i18n>
<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header
			class="main-header"
			:title="$t('onfield_cancel.page.title', { 'serviceId': service.service_no })"/>
		<div>
			<div class="page-action-right restart-button">
				<a-button type="primary" @click="handleRestart">
					{{ $t('onfield_cancel.page.button') }}
				</a-button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		service: {
			type: null,
			default: {},
		},
	},
	methods: {
		handleRestart() {
			this.$router.push({ name: 'onfield/create'})
		},
	},
}
</script>
<style lang="less" scoped>
.main-header {
	text-align: center;
}
.restart-button {
	margin: 10px 0 0 0;
}
</style>
